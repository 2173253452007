import * as React from "react"
import Layout from '../components/layout'

// markup
const Kontakt = () => {
  return (
    <Layout pageTitle="Kontakt">
      <div className="container bg-dark text-light p-5">
        <div className="row">
          <div className="col">
            <div className="text-center">
              <h1 className="text-center mb-5">Kontakt</h1>
              <p>Schreib uns eine E-Mail. Wir freuen uns auf deine Nachricht.</p>
              <p><a href="mailto:mail@bcyber.de">mail@bcyber.de</a></p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Kontakt
